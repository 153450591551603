import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";

import styles from "./StripPrimaryTwoColour.module.scss";

const propTypes = {
	image: PropTypes.shape(),
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
		.isRequired,
	subTitle: PropTypes.element.isRequired,
	fullSized: PropTypes.bool
};

const defaultProps = {
	fullSized: true,
	image: null
};

// ${styles.whatIDoBlock
function StripPrimaryTwoColour({ title, subTitle, image, fullSized }) {
	let renderedImg = null;
	let demoButton = null;
	let backgroundBlock = null;
	let topPad = "p-t-lg";
	let botPad = "m-b-lg";
	if (fullSized) {
		renderedImg = (
			<div
				className={`${styles.primaryImageContainer} column is-half-desktop  is-one-third-tablet is-half-mobile m-b-md `}
			>
				<Img
					fluid={image}
					alt=""
					fadeIn={false}
					className={`${styles.imageSize} forceRightAlignColumn ${styles.primaryImage}`}
				/>
			</div>
		);

		demoButton = (
			<a role="button" className="button is-outlined">
				Book a demo
			</a>
		);

		backgroundBlock = <div className={`${styles.backgroundRec}`} />;
		topPad = "p-t-md";
		botPad = "m-b-xl";
	}

	return (
		<section>
			<div className={`hero ${styles.primaryBarOffset} ${topPad}`}>
				<div className="columns is-vcentered">
					<div
						className={`column is-half-desktop is-half-tablet is-full-mobile`}
					></div>
				</div>
			</div>
			<div className={`darkBar ${styles.secondaryBar}`}></div>
			<div
				className={` ${styles.backgroundHelper} m-b-xxl ${styles.overlappingBarText}`}
			>
				<div className="container">
					<div className={`${styles.overlapTextContainer}`}>
						<h1 className="is-size-2">{title}</h1>
						{subTitle}
						{demoButton}
					</div>
				</div>
			</div>
		</section>
	);
}

StripPrimaryTwoColour.propTypes = propTypes;
StripPrimaryTwoColour.defaultProps = defaultProps;

export default StripPrimaryTwoColour;
