import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";

// Local imports
import Layout from "src/layouts/LayoutWhiteHeader";
import StripPrimary from "src/components/generic/StripPrimary/StripPrimary";
import StripTitle from "src/components/generic/StripTitle/StripTitle";
import StripsAlternatingContent from "src/components/generic/StripsAlternatingContent/StripsAlternatingContent";
import SEO from "src/components/generic/SEO/SEO";
import config from "data/SiteConfig";
import Tabs from "src/components/generic/Tabs/Tabs";
import StripPrimaryTwoColour from "src/components/generic/StripPrimaryTwoColour/StripPrimaryTwoColour";

// Styles
// import styles from "./index.module.scss";

const stripPrimary = {
	title: "Data sources",
	subTitle: (
		<p>
			We load a wide variety of different data sources, some more common
			than others.
		</p>
	)
};

const alternatingContent = [
	{
		subTitle: "We'll get all your data in one place, owned by you.",
		content: (
			<React.Fragment>
				<p>
					The four most common data sources teams want our help
					handling are:
				</p>
				<ul>
					<li>Google Search Console</li>
					<li>Google Analytics (UA & GA4)</li>
					<li>Rank tracking</li>
					<li>Log Data</li>
				</ul>
				<p>
					But we can integrate any kind of SEO data - everything from
					crawl & site speed to internal sales data. Let us know what
					you&apos;re trying to accomplish and we&apos;ll sort it out.
				</p>
			</React.Fragment>
		),
		image: null
	}
];

// const contentListFour = [
// 	{
// 		title: "All of your data in one place.",
// 		content: (
// 			<React.Fragment>
// 				<p>
// 					You registered 5 subfolders in Google Search Console,
// 					but you need to pull them all together to get a full
// 					picture.
// 				</p>
// 				<p>
// 					Your blog is Wordpress, but your site is Magento.
// 					You&#39;ve got two different sources of logs you need to
// 					pull together to run a proper analysis.
// 				</p>
// 				<p>
// 					You&#39;ve got your pre-migration domain and your
// 					post-migration domain. You want search console data from
// 					both to monitor your migration.
// 				</p>
// 				<p>We&apos;ll get all your data in one place.</p>
// 			</React.Fragment>
// 		),
// 		icon: <FaLayerGroup />
// 	},
// 	{
// 		title: "No more artificial limits.",
// 		content: (
// 			<React.Fragment>
// 				<p>
// 					The Google Search Console interface only gives you 1,000
// 					rows of data. And it only stores 16 months. We&apos;ll
// 					store all of it for as long as you want and let you
// 					filter & segment however you want.
// 				</p>
// 				<p>
// 					Google Analytics samples if you use any kind of segments
// 					or filters. We&apos;ll store your data and help you
// 					avoid sampling.
// 				</p>
// 				<p>
// 					Log files won&#39;t even fit in a spreadsheet. We can
// 					help you handle any scale.
// 				</p>
// 				<p>
// 					We store all your data sources and even if you leave us,
// 					you can take the data with you.
// 				</p>
// 			</React.Fragment>
// 		),
// 		icon: <FaProjectDiagram />
// 	},
// ]

const searchConsoleAlternating = [
	{
		subTitle: "Backfilling Search Console",
		content: (
			<React.Fragment>
				<p>
					Google Search Console is a brilliant free tool, but if
					you&apos;re serious about search, you&apos;ll often bump
					into its limitations. You get a tiny fraction of the data in
					the interface.
				</p>
				<p>
					The BigQuery Search Console export is the best way to get
					this, but it doesn&apos;t backfill. We&apos;ll backfill all
					your data and align it with your new ongoing data.
				</p>
			</React.Fragment>
		),
		image: null
	},
	{
		subTitle: "Working with Search Console data",
		content: (
			<React.Fragment>
				<p>
					Once your data is loaded, you need to be able to use it.
					We&apos;ve got years of SEO and SQL expertise to help you
					get the most out of your data.
				</p>
				<p>
					We can help you handle everything from basics like plotting
					clicks over time to complex questions like finding URLs
					which cannibalise & joining SC to your GA & Adwords data.
				</p>
				<p>
					We&apos;ve got a query builder and reporting pipelines to
					help you get the most out of it.
				</p>
			</React.Fragment>
		),
		image: null
	},
	{
		subTitle: "Getting more data out of inspect",
		content: (
			<React.Fragment>
				<p>
					Search Console doesn&apos;t just have traffic data, you can
					also get indexability data from it.
				</p>
				<p>
					We&apos;ll let you bulk extract this indexability data
					automatically to allow for larger scale analysis of
					indexation problems.
				</p>
			</React.Fragment>
		),
		image: null
	}
];

const logDataAlternating = [
	{
		subTitle: "Log data at your fingertips",
		content: (
			<React.Fragment>
				<p>
					Log data is one of the most important data sources we have
					as SEOs.
				</p>
				<p>
					Fixing crawling and indexing, understanding freshness,
					prioritising, debugging weird anomalies. It&apos;s brilliant
					at them.
				</p>
				<p>
					But log files are large and need processing. We take care of
					all of that and give you easy access to all of it.
				</p>
			</React.Fragment>
		),
		image: null
	},
	{
		subTitle: "Processing the log data",
		content: (
			<React.Fragment>
				<p>
					We run DNS/ASN lookups on all our log data so you&apos;ll
					know what is actually coming from Google.
				</p>
				<p>
					We&apos;ll work with your devs to get the log data and
					validate it, making sure you&apos;ve got all the logs and
					no-one missed that Wordpress subfolder on your Magento
					site...
				</p>
			</React.Fragment>
		),
		image: null
	}
];

const googleAnalyticsAlternating = [
	{
		subTitle: "Sampling & data limits",
		content: (
			<React.Fragment>
				<p>
					UA had sampling. When we&apos;re backing up your data
					we&apos;ll make sure we get as much data as possible out.
				</p>
				<p>
					GA4 still has sampling, but the more common problem is API
					limits. If you&apos;re a large site using the Looker Studio
					connector you&apos;ll run into limits.
				</p>
				<p>
					We&apos;ll get you set-up with the raw data so you
					don&apos;t bump into any limits and the reports always work.
				</p>
			</React.Fragment>
		),
		image: null
	},
	{
		subTitle: "Working with the raw export",
		content: (
			<React.Fragment>
				<p>
					The easiest way to get access to the raw, unlimited data
					from GA4 is with the BigQuery export.
				</p>
				<p>
					It comes out as an event stream however and so you
					can&apos;t just query it like another data source it
					requires you to process and work with it.
				</p>
				<p>
					We&apos;ll set-up your GA4 just like all our other data
					sources, build pipelines for it, plug it into reports and
					get it ready for easy ad-hoc analysis.
				</p>
			</React.Fragment>
		),
		image: null
	},
	{
		subTitle: "Working with analytics more quickly",
		content: (
			<React.Fragment>
				<p>
					Everytime you change anything in the Google Analytics
					interface it reloads your data.
				</p>
				<p>
					Setting up and exploring data with it is slow. Exporting
					large reports from it is impossible. With Piped Out you can
					quickly have all the data ready to go with just a handful of
					clicks.
				</p>
			</React.Fragment>
		),
		image: null
	}
];

const rankingDataAlternating = [
	{
		subTitle: "Tracking all parts of the SERP",
		content: (
			<React.Fragment>
				<p>
					We&apos;ll ingest rank tracking data from many of the big
					providers, but we primarily recommend Data For SEO.
				</p>
				<p>
					It provides incredibly rich SERP data which allows you to
					dig into every part of the SERP, the PAA, the local pack,
					ratings snippets and everything else.
				</p>
				<p>
					With SGE and richer SERPs on the horizon, you&apos;re going
					to want the best possible data.
				</p>
			</React.Fragment>
		),
		image: null
	},
	{
		subTitle: "Rank tracking reports",
		content: (
			<React.Fragment>
				<p>
					To see the sort of things you can accomplish with the rich
					rank tracking model take a look at our example report
					templates.
				</p>
			</React.Fragment>
		),
		secondContent: (
			<React.Fragment>
				<div className="reportLink m-r-md">
					<h3 className="is-size-5">
						<a
							href="https://lookerstudio.google.com/u/0/reporting/6848ee68-c73c-40a5-99bb-90da18fc26d5/"
							target="blank"
							rel="noopener noreferrer"
						>
							Branded SERP Monitoring
						</a>
					</h3>
					<span>Monitor and control your brand image on Google.</span>
				</div>
				<div className="reportLink m-r-md">
					<h3 className="is-size-5">
						<a
							href="https://lookerstudio.google.com/u/0/reporting/a2c4a21e-26a3-4cea-9348-d9d9d94c6535/"
							target="blank"
							rel="noopener noreferrer"
						>
							Rank Tracking
						</a>
					</h3>
					<span>Analyse rich rank tracking data.</span>
				</div>
			</React.Fragment>
		)
	},
	{
		subTitle: "Own all your rank tracking data",
		content: (
			<React.Fragment>
				<p>
					if you decide to stop using us, all the data is in your
					warehouse, it&apos;s yours.
				</p>
				<p>
					No more missing data because business requirements have
					changed.
				</p>
			</React.Fragment>
		),
		image: null
	}
];

const linkDataAlternating = [
	{
		subTitle: "Your own personal link database.",
		content: (
			<React.Fragment>
				<p>
					We can integrate with main link providers so you can keep
					all your sites&apos; link data ready to access.
				</p>
				<p>
					We&apos;ll help you work at scale across millions of
					backlinks for large websites and properties, with quick,
					easily repeatable analysis.
				</p>
			</React.Fragment>
		),
		image: null
	},
	{
		subTitle: "Joining links with other data sources",
		content: (
			<React.Fragment>
				<p>
					Links are good by themselves and even better with other data
					sources.
				</p>
				<p>
					Trying to decide if you want to remove a site section? Go
					check traffic and links.
				</p>
				<p>
					Looking to optimise internal links? Connect crawl data with
					link and select the pages with backlinks but few outlinks.
				</p>
			</React.Fragment>
		),
		image: null
	}
];

const crawlDataAlternating = [
	{
		subTitle: "Loading your crawl data",
		content: (
			<React.Fragment>
				<p>
					We can integrate with cloud crawling providers or we can
					help you set-up a process to load your local data (like
					Screaming Frog or Sitebulb crawls).
				</p>
				<p>
					We can either store multiple crawls or just keep your most
					recent one to work with.
				</p>
			</React.Fragment>
		),
		image: null
	},
	{
		subTitle: "Better together",
		content: (
			<React.Fragment>
				<p>
					We focus on combining the crawl data with other data
					sources.
				</p>
				<p>
					You can combine with backlinks, branded traffic,
					conversions, Google requests.
				</p>
				<p>
					It&apos;ll make it easier to prioritise and you&apos;ll be
					able to find different opportunities.
				</p>
			</React.Fragment>
		),
		image: null
	}
];

const propTypes = {
	data: PropTypes.shape().isRequired
};

const Index = ({ data }) => {
	// Set images
	alternatingContent[0].image = data.alternatingImg0.childImageSharp.fluid;
	searchConsoleAlternating[0].image = data.sc0.childImageSharp.fluid;
	searchConsoleAlternating[1].image = data.sc1.childImageSharp.fluid;
	searchConsoleAlternating[2].image = data.sc2.childImageSharp.fluid;
	googleAnalyticsAlternating[0].image = data.ga0.childImageSharp.fluid;
	googleAnalyticsAlternating[1].image = data.ga2.childImageSharp.fluid;
	googleAnalyticsAlternating[2].image = data.ga1.childImageSharp.fluid;
	logDataAlternating[0].image = data.log0.childImageSharp.fluid;
	logDataAlternating[1].image = data.log1.childImageSharp.fluid;
	rankingDataAlternating[0].image = data.ranking0.childImageSharp.fluid;
	rankingDataAlternating[1].image = data.ranking1.childImageSharp.fluid;
	rankingDataAlternating[2].image = data.ranking2.childImageSharp.fluid;
	linkDataAlternating[0].image = data.link0.childImageSharp.fluid;
	linkDataAlternating[1].image = data.link1.childImageSharp.fluid;
	crawlDataAlternating[0].image = data.crawl0.childImageSharp.fluid;
	crawlDataAlternating[1].image = data.crawl1.childImageSharp.fluid;

	// Set-up tab content.
	const searchConsoleStrip = (
		<StripsAlternatingContent stripList={searchConsoleAlternating} />
	);
	const logStrips = (
		<StripsAlternatingContent stripList={logDataAlternating} />
	);
	const gaStrips = (
		<StripsAlternatingContent stripList={googleAnalyticsAlternating} />
	);
	const rankStrips = (
		<StripsAlternatingContent stripList={rankingDataAlternating} />
	);
	const linkStrips = (
		<StripsAlternatingContent stripList={linkDataAlternating} />
	);
	const crawlStrips = (
		<StripsAlternatingContent stripList={crawlDataAlternating} />
	);
	const tabStripSearchConsole = [
		{
			id: "searchConsole",
			header: "Search Console",
			content: searchConsoleStrip
		},
		{
			id: "logData",
			header: "Log Files",
			content: logStrips
		},
		{
			id: "googleAnalytics",
			header: "Google Analytics",
			content: gaStrips
		},
		{
			id: "rankData",
			header: "Rank Tracking",
			content: rankStrips
		},
		{
			id: "crawlData",
			header: "Crawl Data",
			content: crawlStrips
		},
		{
			id: "linkData",
			header: "Link Data",
			content: linkStrips
		}
	];

	const title = "Data Sources";
	const description =
		"We work with a wide variety of different data sources, some more common than others. Let&apos;s talk about which ones and why.";
	return (
		<Layout>
			<Helmet title={config.siteTitle} />
			<SEO pageTitle={title} pageDescription={description} />
			<StripPrimary
				title={stripPrimary.title}
				subTitle={stripPrimary.subTitle}
				fullSized={false}
				isWhite={true}
			/>
			<section>
				{/* <StripTitle title="What do we load?" /> */}
				<StripsAlternatingContent
					stripList={alternatingContent}
					classes="m-t-lg"
					noBotMargin={true}
				/>
			</section>
			<section>
				<StripTitle title="What data challenges do we help people solve?" />
				<Tabs tabData={tabStripSearchConsole} />
			</section>
		</Layout>
	);
};

Index.propTypes = propTypes;

export default Index;

// The graphql query made here will automatically be entered as the
// prop data in data
export const pageQuery = graphql`
	query DataSources {
		# This gets the main image
		primaryImage: file(
			relativePath: {
				eq: "images/pages/homepage/homepage_stripprimary.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 935, maxHeight: 575) {
					...GatsbyImageSharpFluid
				}
			}
		}
		# Get us the main alternating images
		alternatingImg0: file(
			relativePath: {
				eq: "images/pages/how_does_it_work/people_small.png"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 501, maxHeight: 393) {
					...GatsbyImageSharpFluid
				}
			}
		}
		alternatingImg1: file(
			relativePath: { eq: "images/pages/homepage/bigquery_tables.png" }
		) {
			childImageSharp {
				fluid(maxWidth: 513, maxHeight: 278) {
					...GatsbyImageSharpFluid
				}
			}
		}
		alternatingImg2: file(
			relativePath: { eq: "images/pages/how_does_it_work/bigquery.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 672, maxHeight: 392) {
					...GatsbyImageSharpFluid
				}
			}
		}

		# Get us the tab images
		sc0: file(
			relativePath: { eq: "images/pages/how_does_it_work/sampling.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 838, maxHeight: 437) {
					...GatsbyImageSharpFluid
				}
			}
		}
		sc1: file(
			relativePath: {
				eq: "images/pages/how_does_it_work/multiple_subfolders.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 431, maxHeight: 570) {
					...GatsbyImageSharpFluid
				}
			}
		}
		# Lots of websites
		sc2: file(
			relativePath: {
				eq: "images/pages/use_cases/different_properties.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 501, maxHeight: 393) {
					...GatsbyImageSharpFluid
				}
			}
		}
		log0: file(
			relativePath: { eq: "images/pages/how_does_it_work/log_files.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 879, maxHeight: 555) {
					...GatsbyImageSharpFluid
				}
			}
		}
		log1: file(
			relativePath: {
				eq: "images/pages/how_does_it_work/googlebot_ips.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 328, maxHeight: 456) {
					...GatsbyImageSharpFluid
				}
			}
		}
		ga0: file(
			relativePath: {
				eq: "images/pages/how_does_it_work/ga_sampling.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 499, maxHeight: 146) {
					...GatsbyImageSharpFluid
				}
			}
		}
		ga1: file(
			relativePath: { eq: "images/pages/how_does_it_work/ga_loading.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 580, maxHeight: 389) {
					...GatsbyImageSharpFluid
				}
			}
		}
		ga2: file(relativePath: { eq: "images/pages/ga_4_bq_export.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 688, maxHeight: 667) {
					...GatsbyImageSharpFluid
				}
			}
		}
		ranking0: file(
			relativePath: {
				eq: "images/pages/how_does_it_work/serp_features.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 575, maxHeight: 344) {
					...GatsbyImageSharpFluid
				}
			}
		}
		ranking1: file(
			relativePath: { eq: "images/pages/rank_hotel_data.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 840, maxHeight: 766) {
					...GatsbyImageSharpFluid
				}
			}
		}
		ranking2: file(
			relativePath: { eq: "images/pages/rank_data_bigquery.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 612, maxHeight: 419) {
					...GatsbyImageSharpFluid
				}
			}
		}
		crawl0: file(
			relativePath: {
				eq: "images/pages/how_does_it_work/screaming_frog_crawls.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 526, maxHeight: 206) {
					...GatsbyImageSharpFluid
				}
			}
		}
		crawl1: file(
			relativePath: {
				eq: "images/pages/how_does_it_work/query_building_crawler.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 879, maxHeight: 658) {
					...GatsbyImageSharpFluid
				}
			}
		}
		link0: file(
			relativePath: {
				eq: "images/pages/how_does_it_work/backlinks_table.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 557, maxHeight: 326) {
					...GatsbyImageSharpFluid
				}
			}
		}
		link1: file(
			relativePath: {
				eq: "images/pages/how_does_it_work/backlink_questions.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 517, maxHeight: 236) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;
